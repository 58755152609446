import { Box, Typography } from '@material-ui/core'
import React from 'react'

export default function NoDataFound() {
  return (
    <Box align="center" mt={1}>
      <Typography variant="h1" style={{ color: '#fff', fontSize: '14px' }}>
        No data found!
      </Typography>
    </Box>
  )
}

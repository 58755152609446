import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { apiRouterCall } from "src/constants/services/ApiForAll";
import ConfirmationDialogBox from "./ConfirmationDialogBox";
import NoDataFound from "./NoDataFound";
import CommentListSkeleton from "src/Skeletons/CommentListSkeleton";
import NotificationPageCard from "./NotificationPageCard";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  notificationComponent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notificationBox: {
    "& h5": {
      fontSize: "24px",
    },
  },
  displaySpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  dialogPaper: {
    position: "fixed",
    top: "66px",
    right: "91px",
    padding: "15px",
    margin: 0,
    maxWidth: "414px",
    height: "calc(100% - 141px)",
    maxHeight: "600px",
    minHeight: "433px",
    borderRadius: "12px",
    [theme.breakpoints.down("sm")]: {
      right: "0px",
    },
  },
  buttonSeeAll: {
    color: "#fff",
    textTransform: "none",
  },
  clearAllButton: {
    color: "#DF3939",
    background: "transparent",
    textTransform: "none",
  },
}));

export default function Notification({ handleOpen, handleClose }) {
  const classes = useStyles();
  const history = useHistory();
  const [notiLoading, setNotiLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationListData, setNotificationListData] = useState([]);
  const [open, setOpen] = useState({
    openModal: false,
    keyModal: undefined,
  });

  const clearNotificationApi = async (key) => {
    try {
      setIsLoading(true);
      const response = await apiRouterCall({
        method: "DELETE",
        endPoint: "clearNotification",
        paramsData: {
          _id: key ? open?.keyModal : undefined,
        },
      });
      if (response.data.code === 200) {
        toast.success(response.data.message);
        getNotificationList();
        setOpen({
          openModal: false,
          keyModal: undefined,
        });
        history.push("/");
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getNotificationList = async (source) => {
    try {
      setNotiLoading(true);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "notificationList",
        paramsData: { page: 1, limit: "10" },
        source: source,
      });
      if (response.data.code === 200) {
        setNotificationListData(response.data.data.docs);
      } else {
        setNotificationListData([]);
      }
      setNotiLoading(false);
    } catch (error) {
      console.log(error);
      setNotiLoading(false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    getNotificationList(source);
    return () => source.cancel();
  }, []);

  return (
    <>
      <Dialog
        open={handleOpen}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          className: classes.dialogPaper,
        }}
      >
        <DialogContent
          style={{
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <Box className={classes.notificationComponent}>
            <Box className={classes.displaySpaceBetween}>
              <Typography variant="h6" color="#fff">
                Notifications
              </Typography>
              {notificationListData?.length > 4 && (
                <Button
                  className={classes.buttonSeeAll}
                  onClick={() => history.push("/notifications")}
                >
                  See all
                </Button>
              )}
            </Box>
            {notificationListData &&
              notificationListData.slice(0, 4).map((item, index) => (
                <Box item key={index}>
                  <NotificationPageCard data={item} setOpen={setOpen} />
                </Box>
              ))}
            {notiLoading &&
              [1, 2, 3].map((itm) => <CommentListSkeleton key={itm} />)}
            {!notiLoading && notificationListData.length === 0 && (
              <Box width="100%">
                <NoDataFound />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", paddingBottom: "0px" }}
        >
          {notificationListData.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.clearAllButton}
              onClick={() => setOpen({ ...open, openModal: true })}
            >
              Clear all
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {open.openModal && (
        <ConfirmationDialogBox
          openModal={open.openModal}
          handleClose={() =>
            setOpen({
              openModal: false,
              keyModal: undefined,
            })
          }
          heading={`${open.keyModal ? "Delete" : "Clear all"} Notification`}
          description={`Are you sure you want to ${
            open.keyModal ? "delete" : "clear all"
          } notification?`}
          isLoading={isLoading}
          HandleConfirm={() => clearNotificationApi(open.keyModal)}
        />
      )}
    </>
  );
}

import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import CardHeader from "@material-ui/core/CardHeader";

const useStyles = makeStyles((theme) => ({
  postBox: {
    marginTop: "15px",
    "& .MuiCardHeader-root": {
      padding: "0 0 8px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
    "& .eventBox": {
      width: "140px",
      height: "140px",
      borderRadius: "10px",
    },
  },
  circleBox: {
    width: "40px", // You can adjust size based on your needs
    height: "40px",
  },
}));

export default function CommentListSkeleton({ type }) {
  const classes = useStyles();

  return (
    <Box className={classes.postBox}>
      <CardHeader
        avatar={
          <Skeleton
            animation="wave"
            variant="circle"
            className={classes.circleBox}
          />
        }
        title={
          <Skeleton
            animation="wave"
            height={20}
            width={"60%"}
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={15} width="30%" />}
      />
    </Box>
  );
}

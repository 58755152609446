import React from "react";
import { Redirect } from "react-router-dom";

export default function AuthGuard(props) {
  const { children } = props;
  if (!sessionStorage.getItem("userAddress")) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
}

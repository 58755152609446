export const baseurl = process.env.REACT_APP_BASE_URL;
export const unityBaseurl = process.env.REACT_APP_UNITY_BASE_URL;

// export const unityBaseurl = "http://172.16.11.253:1932";
// export const baseurl = "http://172.16.6.118:1929"; //local

let user = `${baseurl}/api/v1`;
let garage = `${user}/garage`;
let circuit = `${user}/circuit`;
let event = `${user}/event`;
let admin = `${user}/admin`;
let static1 = `${user}/static`;

const Apiconfigs = {
  //user
  connectWallet: `${user}/user/connectWallet`,
  profile: `${user}/user/getProfile`,
  updateProfile: `${user}/user/upcomingEvents`,
  garages: `${user}/user/garages`,
  buyGarage: `${user}/user/buyGarage`,
  createNFT: `${user}/nft/createNFT`,
  listNfts: `${user}/nft/listNfts`,
  allPracticeModes: `${user}/user/allPracticeModes`,
  mediaList: `${user}/user/mediaList`,
  upcomingEvents: `${user}/user/upcomingEvents`,
  pastEvents: `${user}/user/pastEvents`,
  getEventDetails: `${user}/user/getEventDetails`,
  subscribeNewsletter: `${user}/user/subscribeNewsletter`,
  assignDriver: `${user}/user/assignDriver`,
  driverConfirmation: `${user}/user/driverConfirmation`,
  editProfile: `${user}/user/editProfile`,
  listTeamUser: `${user}/user/listTeam`,
  boostPackage: `${user}/user/boostPackage`,
  buyBoostGarage: `${user}/user/buyBoostGarage`,
  notificationList: `${user}/user/notificationList`,
  clearNotification: `${user}/user/clearNotification`,
  readNotification: `${user}/user/readNotification`,

  ////////////admin/////////
  login: `${admin}/login`,
  forgotPassword: `${admin}/forgotPassword`,
  dashboard: `${admin}/dashboard`,
  userJoinEventList: `${admin}/userJoinEventList`,
  userCreatedEventList: `${admin}/userCreatedEventList`,
  getUserEventDetails: `${admin}/getUserEventDetails`,
  subscribers: `${admin}/subscribers`,
  media: `${admin}/media`,
  listMedia: `${admin}/listMedia`,
  changeMediaStatus: `${admin}/changeMediaStatus`,
  userList: `${admin}/userList`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  user: `${admin}/user`,
  socialLinkList: `${admin}/socialLinkList`,
  socialLink: `${admin}/socialLink`,
  listTeam: `${admin}/listTeam`,
  addTeams: `${admin}/addTeams`,
  editTeam: `${admin}/editTeam`,
  deleteTeam: `${admin}/deleteTeam`,

  //event
  eventSchedule: `${event}/eventSchedule`,
  listEvent: `${event}/listEvent`,
  event: `${event}/event`,
  enterEvent: `${event}/enterEvent`,
  eventRacingDetails: `${event}/eventRacingDetails`,
  joinEvent: `${event}/joinEvent`,
  eventRaceCompleteResult: `${event}/eventRaceCompleteResult`,
  transactions: `${event}/transactions`,

  //static management
  staticContentList: `${static1}/staticContentList`,
  StaticContent: `${static1}/StaticContent`,
  // practice management
  practice: `${user}/practice/practice`,
  listPractice: `${user}/practice/listPractice`,
  customizePractice: `${user}/practice/customizePractice`,
  practiceModeDetails: `${user}/practice/practiceModeDetails`,

  //garageManagement
  garage: `${garage}/garage`,
  listGarage: `${garage}/listGarage`,
  addBoostPackage: `${garage}/addBoostPackage`,
  updateBoostPackage: `${garage}/updateBoostPackage`,

  //circuitmanagement
  circuit: `${circuit}/circuit`,
  listCircuit: `${circuit}/listCircuit`,
};

export default Apiconfigs;

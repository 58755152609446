import Apiconfigs from 'src/apiConfig/config'
import axios from 'axios'
import { toast } from 'react-toastify'
import moment from 'moment'

export const apiRouterCall = async ({
  method,
  endPoint,
  bodyData,
  paramsData,
  token,
  id,
  source
}) => {
  try {
    return await axios({
      method: method,
      url: id ? `${Apiconfigs[endPoint]}/${id}` : Apiconfigs[endPoint],
      headers: {
        token: token ? token : window.sessionStorage.getItem('token')
      },
      data: bodyData ? bodyData : null,
      params: paramsData ? paramsData : null,
      cancelToken: source ? source.token : null
    })
  } catch (error) {
    console.log(error?.response)
    if (error.response) {
      return error?.response
    } else {
      return { data: { message: error?.message } }
    }
  }
}

export const deleteDataAPIHandler = async (endPoint, _id, token) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: Apiconfigs[endPoint],
      headers: {
        token: token || window.sessionStorage.getItem('token')
      },
      params: {
        _id: _id
      }
    })
    if (res.data.code === 200) {
      return true
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
    console.log(error)
    return false
  }
}
export const blockUnblockAPIHandler = async (
  endPoint,
  _id,
  currentStatus,
  token
) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: Apiconfigs[endPoint],
      headers: {
        token: token || window.sessionStorage.getItem('token')
      },
      data: {
        _id: _id,
        status: currentStatus === 'ACTIVE' ? 'BLOCK' : 'ACTIVE'
      }
    })

    if (res.data.code === 200) {
      console.log('responseBlockUnblock', res)
      return res.data
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
    console.log(error)
    return false
  }
}
export const getParticularObjectDataHandler = async (endPoint, _id, token) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: token || window.sessionStorage.getItem('token')
      }
    })
    if (res.data.code === 200) {
      return res.data.data
    }
  } catch (error) {
    console.log(error)
    return false
  }
}
export const addDataAPIHandler = async (endPoint, dataToSend, token) => {
  try {
    const res = await axios({
      method: 'POST',
      url: Apiconfigs[endPoint],
      headers: {
        token: token || window.sessionStorage.getItem('token')
      },
      data: dataToSend
    })
    if (res.data.code === 200) {
      return res.data.data
    } else {
      toast.error(res?.data?.message)
    }
  } catch (error) {
    console.log(error)
    toast.error(error?.response?.data?.message)
    return false
  }
}
export const editDataAPIHandler = async (endPoint, dataToSend, token) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: Apiconfigs[endPoint],
      headers: {
        token: token || window.sessionStorage.getItem('token')
      },
      data: dataToSend
    })

    if (res.data.code === 200) {
      return res.data.data
    } else {
      toast.error(res?.data?.message)
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
    console.log(error)
    return false
  }
}
export const listingAPIHandler = async (
  endPoint,
  page,
  type,
  filterData,
  token
) => {
  try {
    const dataToSend =
      type !== '' && page !== 0 && filterData !== undefined
        ? {
            limit: 10,
            page: page,
            type: type,
            search: filterData.searchKey !== '' ? filterData.searchKey : null,
            toDate:
              filterData.endDate !== ''
                ? moment(
                    filterData.endDate,
                    'dd.MM.yyyy HH:mm:ss'
                  ).toISOString()
                : null,
            fromDate:
              filterData.startDate !== ''
                ? moment(
                    filterData.startDate,
                    'dd.MM.yyyy HH:mm:ss'
                  ).toISOString()
                : null
          }
        : type !== '' && page === 0
        ? {
            type: type
          }
        : type === undefined && page === undefined && filterData === undefined
        ? {
            limit: 10,
            page: page,
            type: type
          }
        : {
            limit: 10,
            page: page,
            search: filterData.searchKey !== '' ? filterData.searchKey : null,
            toDate:
              filterData.endDate !== ''
                ? moment(
                    filterData.endDate,
                    'dd.MM.yyyy HH:mm:ss'
                  ).toISOString()
                : null,
            fromDate:
              filterData.startDate !== ''
                ? moment(
                    filterData.startDate,
                    'dd.MM.yyyy HH:mm:ss'
                  ).toISOString()
                : null
          }
    const res = await axios({
      method: 'GET',
      url: Apiconfigs[endPoint],
      headers: {
        token: token ? token : window.sessionStorage.getItem('token')
      },
      params: dataToSend
    })
    if (res.data.code === 200) {
      return res.data.data
    }
  } catch (error) {
    console.log(error)
    return false
  }
}

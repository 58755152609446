import React from 'react'
import {
  Box,
  IconButton,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core'
import { IoMdClose } from 'react-icons/io'

const useStyles = makeStyles(theme => ({
  notificationContainer: {
    position: 'relative'
  },
  notificationCard: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#E6E6FA',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      '& $notificationText': {
        color: '#000'
      },
      '& $iconStyle': {
        color: '#000'
      }
    }
  },
  displaySpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%'
  },
  mainNotification: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  notificationText: {
    fontSize: '16px',
    wordBreak: 'break-all'
  },
  timeAgo: {
    fontSize: '14px',
    color: '#667085',
    marginTop: theme.spacing(0.5)
  },
  iconStyle: {
    color: '#00FFFF'
  }
}))

const notificationStatus = [
  {
    status: 'SUCCESS',
    image: '/images/Notifications/success.svg'
  },
  {
    status: 'CANCELLED',
    image: '/images/Notifications/info.svg'
  },
  {
    status: 'FAILED',
    image: '/images/Notifications/failed.svg'
  }
]

function formatTimeAgo (createdAt) {
  const now = new Date()
  const diffInSeconds = (now - new Date(createdAt)) / 1000
  if (diffInSeconds < 60) return 'A few seconds ago'
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`
  return `${Math.floor(diffInSeconds / 3600)} hours ago`
}

export default function NotificationPageCard ({ data, setOpen }) {
  const classes = useStyles()

  return (
    <Box className={classes.notificationContainer}>
      <Paper elevation={2} className={classes.notificationCard}>
        <Box className={classes.displaySpaceBetween}>
          <Box className={classes.mainNotification}>
            {/* <img
              style={{ marginTop: "3%" }}
              src={
                notificationStatus.find(
                  (item) => item.status === data?.notificationStatus
                )?.image || "/images/Notifications/success.svg"
              }
              alt="Notification Icon"
            /> */}
            <Box ml={1.5} mr={2}>
              <Typography
                variant='h6'
                color='#fff'
                className={classes.notificationText}
              >
                {data?.message || '...'}
              </Typography>
              <Typography className={classes.timeAgo}>
                {formatTimeAgo(data?.createdAt)}
              </Typography>
            </Box>
          </Box>
          <IconButton
            onClick={() =>
              setOpen({
                openModal: true,
                keyModal: data?._id
              })
            }
          >
            <IoMdClose className={classes.iconStyle} />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  )
}

import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/events",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Events/Index")),
  },
  {
    exact: true,
    path: "/add-event",
    layout: HomeLayout,
    // component: lazy(() => import("src/views/pages/Events/EventAdd")),
    component: lazy(() => import("src/views/pages/Dashboard/Addevent")),
  },
  {
    exact: true,
    path: "/your-event",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Events/YourEvent")),
  },
  {
    exact: true,
    path: "/notifications",
    layout: HomeLayout,
    component: lazy(() => import("src/component/AllNotification")),
  },
  {
    exact: true,
    path: "/users-card",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/User/UserCars")),
  },
  {
    exact: true,
    path: "/select-car",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SellingPage/SelectCar")),
  },
  {
    exact: true,
    path: "/race-results",
    // layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/RaceResults/raceResults")
    ),
  },
  {
    exact: true,
    path: "/share-driver",
    // layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/startRace/startRace")),
  },

  {
    exact: true,
    path: "/garage",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SellingPage/Selling")),
  },

  {
    exact: true,
    path: "/buy-booster",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SellingPage/Selling")),
  },
  {
    exact: true,
    path: "/garage-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/SellingPage/GarageDetail")),
  },
  {
    exact: true,
    path: "/learn-more",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/LearnMore/HorizontalLinearStepper")
    ),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/Mint")),
  },
  {
    exact: true,
    path: "/race-time",

    component: lazy(() => import("src/component/RaceTime")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/About")),
  },
  {
    exact: true,
    path: "/view-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Userdetail")),
  },
  {
    exact: true,
    path: "/add-team",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addteam")),
  },
  {
    exact: true,
    path: "/team-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/TeamManagement")),
  },
  {
    exact: true,
    path: "/media",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/Media")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/PrivacyAndPolicy")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Coverphoto")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Editprofile")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/eventmanagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Eventmanagement")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/practice-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/PracticeManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },

  {
    exact: true,
    adminAuth: true,
    path: "/garage-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Garagemanagement")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/Circuitmanagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Circuit")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/staticManagement/StaticManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/social-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/socialManagement/socialMagements")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/staticManagement/Edit")
    ),
  },

  {
    exact: true,
    // adminAuth: true,
    path: "/addevent",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addevent")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/Add-Practice",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addpractice")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/usermanagemant",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Usermanagement")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/newsletter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NewsLetterManagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/add-garage",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addgarage")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/add-booster",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/AddBooster")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/addgallary",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addgallary")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/addcircuit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addcircuit")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/addpartners",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Addpartners")),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/gallarymanagement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Gallarymanagement")
    ),
  },
  {
    exact: true,
    adminAuth: true,
    path: "/partnersmanagement",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Partnersmanagement")
    ),
  },

  {
    exact: true,
    path: "/add-subadmin",
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/Admin/SubAdmin")),
  },
  {
    exact: true,
    path: "/login",
    // guard: true,
    component: lazy(() => import("src/views/auth/LogIn/index")),
  },

  {
    exact: true,
    path: "/forgot-password",
    // guard: true,
    component: lazy(() => import("src/views/auth/forgot-password/index")),
  },
  {
    exact: true,
    path: "/verify-otp",
    // guard: true,
    component: lazy(() => import("src/views/auth/Verify-Otp/VerifyOtp")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard: true,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  Avatar,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { SiDiscord } from "react-icons/si";
import CloseIcon from "@material-ui/icons/Close";
import MetaMaskPopup from "src/component/MetaMaskPopup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import { apiRouterCall } from "src/constants/services/ApiForAll";
import axios from "axios";
import Notification from "src/component/Notification";
import { FaBell } from "react-icons/fa";

const headersData = [
  {
    label: "EVENTS",
    href: "/events",
  },
  {
    label: "MINT",
    href: "/mint",
  },
  {
    label: "GARAGE",
    // href: "/garage",
    href: "/select-car",
  },
  {
    label: "LEARN",
    href: "/learn-more",
  },
  // {
  //   label: "DASHBORD",
  //   href: "/eventmanagement"
  // },
  {
    label: "PROFILE",
    href: "/profile",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#B1AFAF",
    height: "31px",
    padding: "0px 5px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },

    "&:hover": {
      color: "#00FFFF",
    },
    "&:active": {
      color: "#00FFFF !important",
    },
  },
  menuButton1: {
    width: "100%",
    display: "flow-root",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#031033",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    position: "absolute",
    top: "0px",
    right: "15px",
    fontSize: "25px",
  },
  drawericon1: {
    position: "absolute",
    top: "0px",
    right: "45px",
    fontSize: "25px",
  },

  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #ec0066",
    padding: "0 15px",
    background: "#ec0066",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "#fff",
      color: "#ec0066",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  TopBarmenuButton: {
    background:
      "radial-gradient(185.51% 95.21% at 50% 50%, #131313 0%, rgba(19, 19, 19, 0) 100%), #4D4D4D",
    border: "1px solid #5F5D5D",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    color: "#ffffff",
    fontSize: "15px",
    height: "40px",
    minWidth: "120px",
    fontFamily: "'Orbitron', sans-serif",
    lineHeight: " 21px",
    padding: "10px 0px",
    marginRight: "10px",
  },
}));

export default function Header() {
  const location = useLocation();
  const { account, chainId } = useWeb3React();
  const [openNotification, setOpenNotification] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openmetamask, setOpenMetaMask] = React.useState(false);
  const [headersDataNav, setHeadersDataNav] = useState([]);
  const user = useContext(UserContext);

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    TopBarmenuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    drawericon1,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    if (account) {
      setOpenMetaMask(false);
    }
  }, [account]);
  useEffect(() => {
    if (!account) {
      const filterFunForHearderData = headersData.filter((data) => {
        return data.label !== "PROFILE" && data.label !== "GARAGE";
      });
      setHeadersDataNav(filterFunForHearderData);
    } else {
      setHeadersDataNav(headersData);
    }
  }, [account]);
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Grid item lg={8} md={8} align="center">
              {getMenuButtons()}
            </Grid>
            <Grid item lg={4} md={4} align="right">
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box sx={{ marginRight: "5px" }}>
                  {account && (
                    <IconButton
                      onClick={async () => {
                        setOpenNotification(true);
                        if (user?.userData?.unreadNotification > 0) {
                          await apiRouterCall({
                            method: "GET",
                            endPoint: "readNotification",
                          });
                          user.getProfileHandler(
                            sessionStorage.getItem("token")
                          );
                        }
                      }}
                      className="notificationpulse"
                    >
                      <FaBell style={{ fontSize: "24px" }} />
                      {user?.userData?.unreadNotification > 0 && (
                        <div className="pulse">
                          {user?.userData?.unreadNotification}
                        </div>
                      )}
                    </IconButton>
                  )}
                </Box>
                <Button
                  startIcon={<SiDiscord />}
                  className={TopBarmenuButton}
                  target="_blank"
                  href="https://discord.com/"
                >
                  Discord
                </Button>
                <Button
                  className={TopBarmenuButton}
                  style={{
                    letterSpacing: "0.04em",
                    color: "#00FFFF",
                    textShadow: "0px 0px 10px #32EBFB",
                  }}
                  onClick={() => user.connectWalletPopUp()}
                >
                  {account ? sortAddress(account) : "Connect"}
                </Button>
                {account && (
                  <Avatar sizes="small" src={user?.userData?.profilePic} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <>
        <Toolbar className={mainHeader}>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              <img className={logoDrawer} src="images/footerlogo.png" alt="" />

              {getDrawerChoices()}
              <Box pt={2} pl={2}>
                <Button
                  startIcon={<SiDiscord />}
                  className={TopBarmenuButton}
                  target="_blank"
                  href="https://discord.com/"
                >
                  Discord
                </Button>
                <Box mt={2}>
                  {account ? (
                    <>
                      <Button
                        className={TopBarmenuButton}
                        style={{
                          letterSpacing: "0.04em",
                          color: "#00FFFF",
                          textShadow: "0px 0px 10px #32EBFB",
                        }}
                      >
                        {account ? sortAddress(account) : ""}
                      </Button>
                      <Avatar
                        src={user?.userData?.profilePic}
                        style={{ marginTop: "10px" }}
                      />
                    </>
                  ) : (
                    <Button
                      className={TopBarmenuButton}
                      style={{
                        letterSpacing: "0.04em",
                        color: "#00FFFF",
                        textShadow: "0px 0px 10px #32EBFB",
                      }}
                      onClick={() => setOpenMetaMask(true)}
                    >
                      Connect
                    </Button>
                  )}
                </Box>
              </Box>
            </div>
          </Drawer>

          <div>{femmecubatorLogo}</div>
          <Grid container>
            <Grid item xs={10}></Grid>

            <Grid item xs={2}>
              {account && (
                <IconButton
                  onClick={async () => {
                    setOpenNotification(true);
                    if (user?.userData?.unreadNotification > 0) {
                      await apiRouterCall({
                        method: "GET",
                        endPoint: "readNotification",
                      });
                      user.getProfileHandler(sessionStorage.getItem("token"));
                    }
                  }}
                  className={`${drawericon1} notificationpulse`}
                >
                  <FaBell style={{ fontSize: "24px" }} />
                  {user?.userData?.unreadNotification > 0 && (
                    <div className="pulse">
                      {user?.userData?.unreadNotification}
                    </div>
                  )}
                </IconButton>
              )}
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#00FFFF", fontSize: "30px" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </>
    );
  };

  const getDrawerChoices = () => {
    return headersDataNav.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box sx={{ marginLeft: "15px" }}>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersDataNav.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "#030A20",
          border: "none",
          padding: "10px 0px",
        }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      {openNotification && (
        <Notification
          handleOpen={openNotification}
          handleClose={() => setOpenNotification(false)}
        />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>

      <Dialog
        open={openmetamask}
        fullWidth
        maxWidth="xs"
        onClose={() => setOpenMetaMask(false)}
      >
        <IconButton
          style={{
            top: "-42px",
            color: "#fff",
            right: "-9px",
            position: "absolute",
          }}
          onClick={() => setOpenMetaMask(false)}
        >
          <CloseIcon style={{ color: "#FFFFFF" }} />
        </IconButton>
        <DialogContent>
          <MetaMaskPopup user={user} account={account} />
        </DialogContent>
      </Dialog>
    </>
  );
}
